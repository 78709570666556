import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  MenuFilesDisplay,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class MenuPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <div className={"hero"}>
              <h1>Menu</h1>
              <HeroElement
                header={"Menu"}
                tagline={""}
                images={getPageHeroImage({
                  options: fishermanBusinessWebsitePage.components,
                })}
                ctas={[
                  {
                    internal: false,
                    text: "Order Online",
                    link: "http://t.yesware.com/tt/5384be07a7e64c3086464b52e019842002d3896d/a740d08861937993262c196ae3cb7f81/e57c04f7fbb8ab6724bc3dd64232d6c4/ordering.chownow.com/order/15484/locations",
                  },
                ]}
                ctaInverted={true}
                ctaColor={"white"}
                containerColor={"primary"}
                textColor={"white"}
                showMultiple={true}
                overlay={true}
                fullWidth={true}
                parallax={false}
                containerAs={"none"}
                height={"100vh"}
                gutter={false}
              />
            </div>
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={"16"} textAlign={"left"}>
              <MenuFilesDisplay files={fishermanBusiness.menuFiles} />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      menuFiles {
        url
        filename
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Menu" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
